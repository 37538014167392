import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { Grid, GridItem, Box } from "@chakra-ui/react";

import PreviewCompatibleImage from "./PreviewCompatibleImageFluid";

const TeamMembers = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Grid gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr" gap={6} my={6}>
      {posts?.map(({ node: post }) => (
        <Member post={post} key={post.id} />
      ))}
    </Grid>
  );
};

const Member = ({ post }) => {
  return (
    <GridItem colSpan={[6, 3, 2]}>
      <Link to={post.fields.slug}>
        <Box as="article" w="100%" borderWidth="1px" borderRadius="sm" overflow="hidden">
          <PreviewCompatibleImage
            imageInfo={{
              image: post.frontmatter.image,
              alt: `Image thumbnail for ${post.frontmatter.name}`,
            }}
          />

          <Box p="6">
            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
              {post.frontmatter.name}
            </Box>
            <Box fontWeight="light">{post.frontmatter.skill}</Box>
          </Box>
        </Box>
      </Link>
    </GridItem>
  );
};

TeamMembers.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query TeamMembersQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "team-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              html
              fields {
                slug
              }
              frontmatter {
                templateKey
                name
                skill
                twitter
                facebook
                instagram
                image {
                  childImageSharp {
                    fluid(maxWidth: 550, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <TeamMembers data={data} count={count} />}
  />
);
